<template>
	<div class="menuManage" v-loading="loading">
		<div class="navCommon">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item>方案管理</el-breadcrumb-item>
			</el-breadcrumb>
		</div>

		<div class="menuBox">
			<el-button v-if="roleId != 1" type="primary" icon="el-icon-plus" @click="seeprogramme">新增方案</el-button>
			<div class="search">
				<el-input clearable @clear="inputclear" placeholder="请输入方案名称关键字" v-model="input3"
					class="input-with-select">
					<el-button slot="append" icon="el-icon-search" @click="keywordclick"></el-button>
				</el-input>
			</div>
			<div class="menuList">
				<el-table border ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%">
					<!-- <el-table-column type="selection" width="55">
					</el-table-column> -->
					<el-table-column type="index" label="序号" width="50">
					</el-table-column>
					<el-table-column label="名称" prop="poName">
					</el-table-column>
					<el-table-column label="简介" prop="poContext">
					</el-table-column>
					<el-table-column label="创建时间">
						<template slot-scope="scope" v-if="scope.row.poCreateTime">
							{{moment(scope.row.poCreateTime).format('YYYY-MM-DD HH:mm:SS')}}
						</template>
					</el-table-column>
					<el-table-column width="120" label="最新版本">
						<template slot-scope="scope">
							{{scope.row.version>0 ? scope.row.version : '无'}}
						</template>
					</el-table-column>
					<el-table-column width="120" label="审核状态">
						<template slot-scope="scope">
							<template v-if="scope.row.isUp == 2 || scope.row.isUp == 4">
								<el-tooltip effect="dark" :content="scope.row.noExplain" placement="top-start">
									<span>{{scope.row.isUp == 2 ? '初级审核未通过' : '终极审核未通过'}}</span>
								</el-tooltip>
							</template>
							<template v-else>
								{{scope.row.isUp == 0 ? '未审核': scope.row.isUp == 1 ? '初级审核通过' : scope.row.isUp == 3 ? '终极审核通过' : '审核中'}}
							</template>
						</template>
					</el-table-column>
					<!-- <el-table-column label="原始单价" prop="poTotalPrice" show-overflow-tooltip width="120">
						<template slot-scope="scope" v-if="scope.row.poTotalPrice">
							{{scope.row.poTotalPrice}}{{scope.row.unit == 0?'元':'万元'}}
						</template>
					</el-table-column> -->
					<el-table-column label="操作" :width="roleId != 1 ? '200' : '120'">
						<template slot-scope="scope">
							<template v-if="roleId != 1">
								<div class="buttonsteam">
									<el-tooltip class="item" effect="dark" content="修改方案名称" placement="top-start">
										<el-button :disabled="scope.row.isUp == 3 ? true : false" size="mini" @click="seeprogramme(scope.row)" type="primary"
											icon="el-icon-edit">
										</el-button>
									</el-tooltip>
									<el-tooltip class="item" effect="dark" content="删除方案" placement="top-start">
										<el-button :disabled="scope.row.isUp == 3 ? true : false" size="mini" type="primary" icon="el-icon-delete"
											@click="deleteProgramme(scope.row.id)"></el-button>
									</el-tooltip>
									<el-tooltip class="item" effect="dark" content="修改方案设备" placement="top-start">
										<!-- <el-button size="mini"
											@click="$router.push({ path: 'addprogramme',query:{programmeId:scope.row.id,newversion:scope.row.version}})"
											type="primary" icon="el-icon-setting">
										</el-button> -->
										<el-button :disabled="scope.row.isUp == 5 ? true : false" v-if="scope.row.version>0" size="mini"
											@click="$router.push({ path: 'addprogramme',query:{programmeId:scope.row.id,version:scope.row.version,isUp:scope.row.isUp}})"
											type="primary" icon="el-icon-setting">
										</el-button>
										<el-button v-else size="mini"
											@click="$router.push({ path: 'addprogramme',query:{programmeId:scope.row.id,newversion:scope.row.version}})"
											type="primary" icon="el-icon-setting">
										</el-button>
									</el-tooltip>
									<el-tooltip class="item" effect="dark" content="下载当前版本方案" placement="top-start">
										<el-button size="mini" type="primary" icon="el-icon-download"
											@click="programmedownload(scope.row.id,scope.row.version)"></el-button>
									</el-tooltip>
									<el-tooltip  class="item" effect="dark" content="产看历史版本" placement="top-start">
										<el-button  size="mini" type="primary" icon="el-icon-data-line"
											@click="programmesearch(scope.row.id,scope.row.poName,scope.row.version),historydata = scope.row">
										</el-button>
									</el-tooltip>
									<el-tooltip class="item" effect="dark" content="提交审核" placement="top-start">
										<el-button :disabled="scope.row.isUp == 0&&scope.row.version>0 ||  scope.row.isUp == 2&&scope.row.version>0 || scope.row.isUp == 4&&scope.row.version>0  ? false : true"  size="mini" type="primary" icon="el-icon-s-check"
											@click="examineruleForm.poName = '',examineruleForm.poContext = '',examineshow = true,approveApplydata = scope.row">
										</el-button>
									</el-tooltip>
								</div>
							</template>
							<template v-else>
								<el-button size="mini"
									@click="adminexamineshow = true,getProgrammeDetail(scope.row.id,scope.row.version,1)"
									type="primary" icon="el-icon-search">
								</el-button>
								<el-button size="mini" type="primary" icon="el-icon-data-line"
									@click="programmesearch(scope.row.id,scope.row.poName,scope.row.version)">
								</el-button>
							</template>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="pagination" v-if="totalnumber>20">
				<el-pagination :page-size="20" :current-page="pagenum" @current-change="paginationchange" background
					layout="prev, pager, next" :total="totalnumber">
				</el-pagination>
			</div>
		</div>
		<el-dialog :close-on-click-modal="false" :visible.sync="editshow" width="40%" class="addform">
			<template v-if="dialogindex == 0">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
					<el-form-item label="方案名称" prop="poName">
						<el-input v-model="ruleForm.poName"></el-input>
					</el-form-item>
					<el-form-item label="方案简介" prop="poContext">
						<el-input type="textarea" v-model="ruleForm.poContext"></el-input>
					</el-form-item>
				</el-form>
			</template>
			<template v-if="dialogindex == 1">
				<el-input></el-input>
			</template>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="onSubmit">提交</el-button>
				<el-button @click="editshow = false">取消</el-button>
			</span>
		</el-dialog>
		<el-dialog top="6vh" :show-close="false" :close-on-click-modal="false" :visible.sync="historyeditshow"
			width="80%" class="addforms">
			<div class="historybutton">
				<el-button :disabled="historydata.isUp == 5 || historydata.isUp == 1 || historydata.isUp == 3? true : false" v-if="programmehistorydata && programmehistorydata.length>0&&roleId!=1" type="primary"
					@click="$router.push({ path: 'addprogramme',query:{programmeId:childerdatas.id,newversion:version}})">
					重新添加</el-button>
				<el-button class="ioasd" @click="historyeditshow = false">关闭</el-button>
			</div>
			<div v-if="programmehistorydata && programmehistorydata.length>0" class="historydata">
				<div class="box">
					<el-menu default-active="0">
						<el-menu-item :index="index+''" @click="getchilderdatas(item)"
							v-for="(item,index) in programmehistorydata">版本：{{item.version}}</el-menu-item>
					</el-menu>
				</div>
				<div class="historydatabody" v-if="childerdatas">
					<div class="times">时间：{{moment(childerdatas.poCreateTime).format('YYYY-MM-DD HH:mm:SS')
						}}
						<span v-if="roleId!=1">
							<el-tooltip class="item" effect="dark" content="修改当前版本" placement="top-start">
								{{historydata}}
								<el-button :disabled="historydata.isUp == 5 || historydata.isUp == 1 || historydata.isUp == 3? true : false" size="mini" type="primary" icon="el-icon-edit"
									@click="$router.push({ path: 'addprogramme',query:{programmeId:childerdatas.id,newversion:version,version:childerdatas.version}})">
								</el-button>
							</el-tooltip>
							<el-tooltip class="item" effect="dark" content="下载当前版本" placement="top-start">
								<el-button :disabled="historydata.isUp == 5 || historydata.isUp == 1 || historydata.isUp == 3? true : false" size="mini" type="primary" icon="el-icon-download"
									@click="programmedownload(childerdatas.id,childerdatas.version)"></el-button>
							</el-tooltip>
						</span>
						<span class="price">总价：{{childerdatas.overprice}}{{childerdatas.unit == 0 ? '元':'万元'}}</span>
					</div>
					<el-table border ref="multipleTable" :data="childerdatas.tableData" tooltip-effect="dark"
						style="width: 100%">
						<el-table-column type="index" label="序号" width="50">
						</el-table-column>
						<el-table-column type="expand">
							<template slot-scope="props">
								<div>是否订做：{{ props.row.eqCustomMade }}</div>
								<div>发票、税：{{ props.row.eqInvoice }}</div>
								<div>是否安装/安装费：{{ props.row.eqInstall }}</div>
								<div v-if="props.row.eqCustom" v-for="(item,index) in JSON.parse(props.row.eqCustom)">
									{{item.name}}：{{ item.value }}
								</div>
							</template>
						</el-table-column>
						<el-table-column show-overflow-tooltip label="设备类型">
							<template slot-scope="props">
								{{props.row.shixunName}}>{{props.row.nextTypeName}}
							</template>
						</el-table-column>
						<el-table-column prop="eqName" label="名称">
						</el-table-column>
						<el-table-column width="300" prop="eqModel" show-overflow-tooltip label="规格/型号">
						</el-table-column>
						<el-table-column prop="eqManufacturer" show-overflow-tooltip label="厂家信息">
						</el-table-column>
						<el-table-column show-overflow-tooltip prop="eqPurchaseRoute" label="购买途径">
							<template slot-scope="props">
								<template v-if="props.row.eqPurchaseRoute.slice(0,4) == 'http'">
									<a :href="props.row.eqPurchaseRoute" target="_blank">点击跳转</a>
								</template>
								<template v-else>
									{{props.row.eqPurchaseRoute}}
								</template>
							</template>
						</el-table-column>
						<el-table-column prop="number" show-overflow-tooltip width="120" label="数量">
						</el-table-column>
					</el-table>
				</div>
			</div>
			<div v-else>
				<el-empty description="暂无历史版本"></el-empty>
			</div>
		</el-dialog>
		<el-dialog top="6vh" :show-close="false" :close-on-click-modal="false" :visible.sync="examineshow">
			<div>
				<el-form :model="examineruleForm" :rules="examinerules" ref="examineruleForm" label-width="100px"
					class="">
					<el-form-item label="审批人" prop="poName">
						<el-select v-model="examineruleForm.poName" placeholder="请选择审批人">
							<el-option v-for="(item,index) in UserListdatas" :label="item.userName" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="提交备注" >
						<el-input type="textarea" v-model="examineruleForm.poContext"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="approveApply">提交</el-button>
				<el-button @click="examineshow = false">取消</el-button>
			</span>
		</el-dialog>
		<el-dialog width="70%" top="6vh" :visible.sync="adminexamineshow">
			<span class="adminprice">总价：{{admintableData.overprice}}{{admintableData.unit == 0 ? '元':'万元'}}</span>
			<el-table border ref="multipleTable" :data="admintableData.data" tooltip-effect="dark" style="width: 100%">
				<el-table-column type="index" label="序号" width="50">
				</el-table-column>
				<el-table-column type="expand">
					<template slot-scope="props">
						<div>是否订做：{{ props.row.eqCustomMade }}</div>
						<div>发票、税：{{ props.row.eqInvoice }}</div>
						<div>是否安装/安装费：{{ props.row.eqInstall }}</div>
						<div v-if="props.row.eqCustom" v-for="(item,index) in JSON.parse(props.row.eqCustom)">
							{{item.name}}：{{ item.value }}
						</div>
					</template>
				</el-table-column>
				<el-table-column show-overflow-tooltip label="设备类型">
					<template slot-scope="props">
						{{props.row.shixunName}}>{{props.row.nextTypeName}}
					</template>
				</el-table-column>
				<el-table-column prop="eqName" label="名称">
				</el-table-column>
				<el-table-column width="300" prop="eqModel" show-overflow-tooltip label="规格/型号">
				</el-table-column>
				<el-table-column prop="eqManufacturer" show-overflow-tooltip label="厂家信息">
				</el-table-column>
				<el-table-column show-overflow-tooltip prop="eqPurchaseRoute" label="购买途径">
					<template slot-scope="props">
						<template v-if="props.row.eqPurchaseRoute.slice(0,4) == 'http'">
							<a :href="props.row.eqPurchaseRoute" target="_blank">点击跳转</a>
						</template>
						<template v-else>
							{{props.row.eqPurchaseRoute}}
						</template>
					</template>
				</el-table-column>
				<el-table-column prop="number" show-overflow-tooltip width="120" label="数量">
				</el-table-column>
			</el-table>
		</el-dialog>
	</div>
</template>

<script>
	import axios from 'axios';
	import Moment from 'moment'
	export default {
		name: "menu-management",
		data() {
			return {
				historydata:'',
				approveApplydata:'',
				UserListdatas:[],
				admintableData: {data:[]},
				childerdatas: '',
				moment: Moment,
				userId: sessionStorage.getItem('userId'),
				roleId: sessionStorage.getItem('roleId'),
				dialogindex: 0,
				programmedatas: '',
				adminexamineshow: false,
				selectionshow: false,
				editshow: false,
				examineshow: false,
				historyeditshow: false,
				input3: "",
				tableData: [],
				programmehistorydata: [],
				ruleForm: {
					poName: '',
					poContext: ''
				},
				examineruleForm: {
					poName: '',
					poContext: ''
				},
				rules: {
					poName: [{
						required: true,
						message: '请输入方案名称',
						trigger: 'blur'
					}],
					poContext: [{
						required: true,
						message: '请输入方案简介',
						trigger: 'blur'
					}]
				},
				examinerules: {
					poName: [{
						required: true,
						message: '请选择审核人',
						trigger: 'change'
					}],
					poContext: [{
						required: true,
						message: '请输入提交介绍',
						trigger: 'blur'
					}]
				},
				pagenum: 1,
				totalnumber: '',
				datalisttype: 0,
				loading: false,
				version: ''
			}

		},
		created() {
			this.programmeList()
			this.getuserList()
		},
		computed: {

		},
		watch: {

		},
		methods: {
			//提交审核
			approveApply(){
				// console.log(this.approveApplydata)
				// console.log(this.examineruleForm)
				this.$refs['examineruleForm'].validate((valid) => {
					if (valid) {
						this.$post('programme/approveApply',{
							proId:this.approveApplydata.id,
							authorId:this.userId,
							roleId:this.roleId,
							checkUserId:this.examineruleForm.poName,
							checkRoleId:this.roleId == 2 ? 3 : 1,
							sponsorId:this.userId,
							describe:this.examineruleForm.poContext
						}).then(()=>{
							this.$message({
								message: '审核已提交',
								type: 'success'
							});
							this.programmeList()
							this.examineshow = false
						})
					} else {
						this.$message({
							message: '请完善表单信息',
							type: 'warning'
						});
					}
				});
			},
			getuserList() {
				this.$post('login/selectUserList').then((res) => {
					if(res&&res.length>0){
						if(this.roleId == 2 || this.roleId == 4){
							res.forEach((e,m)=>{
								if(e.roleId==3||e.roleId==1){
									this.UserListdatas.push(e)
								}
							})
						}
						if(this.roleId == 3){
							res.forEach((e,m)=>{
								if(e.roleId==1){
									this.UserListdatas.push(e)
								}
							})
						}
					}
				})
			},
			getchilderdatas(val) {
				this.childerdatas = val
				this.getProgrammeDetail(val.id, val.version)
			},
			ahref(id) {
				document.getElementById(id).scrollIntoView()
			},
			//展示历史版本列表
			programmesearch(programmeId, poName, version) {
				this.version = version
				this.programmehistorydata = []
				this.historyeditshow = true
				this.$post('programme/getProgrammehistory', {
					programmeId: programmeId,
					poName: poName,
					version: version
				}).then((res) => {
					if (res && res.length > 0) {
						this.programmehistorydata = res
						this.childerdatas = res[0]
						this.getProgrammeDetail(res[0].id, res[0].version)
					}
				})
			},
			//获取历史版本方案里的设备
			async getProgrammeDetail(id, version, m) {
				await this.$post('programme/getProgrammeDetail', {
					programmeId: id,
					version: version
				}).then((res) => {
					this.admintableData.data = []
					let gh = 0
					res.forEach((e, m) => {
						gh += parseFloat(e.price) * parseFloat(e.number)
					})
					if (res[0].unit == 0) {
						gh = gh.toFixed(2)
					} else {
						gh = gh.toFixed(4)
					}
					if (m) {
						this.admintableData.data = res
						this.admintableData.overprice = gh
						this.admintableData.unit = parseFloat(res[0].unit)
					} else {
						this.childerdatas.tableData = res
						this.childerdatas.overprice = gh
						this.childerdatas.unit = parseFloat(res[0].unit)
						this.$set(this.childerdatas)
					}
				})
			},
			//清除搜索
			inputclear() {
				this.datalisttype = 0
				this.tableData = 0
				this.pagenum = 1
				this.programmeList()
			},
			//分页
			paginationchange(val) {
				this.pagenum = val
				if (this.datalisttype == 0) {
					this.programmeList()
				} else {
					this.keywordsearch()
				}
			},
			//下载
			programmedownload(id, version) {
				this.loading = true
				this.$post('programme/export', {
					programmeId: id,
					version: version
				}).then((res) => {
					if (res) {
						this.Urlstrues(res).then(result => {
							this.loading = false
							if (result) {
								window.location.href = res
							} else {
								this.$message({
									message: '下载地址有误请稍后再试',
									type: 'warning'
								});
							}
						})
					} else {
						this.loading = false
						this.$message({
							message: '该方案没有设备',
							type: 'warning'
						});
					}
				})
			},
			async Urlstrues(url) {
				let data = ""
				await axios.post(url)
					.then(response => {
						if (response) {
							data = true
						} else {
							data = false
						}
					}, err => {
						data = false
					})
				return data
			},
			//删除
			async deleteProgramme(id) {
				await this.$confirm('此操作将删除该方案, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$post('programme/deleteProgramme', {
						programmeId: id
					}).then(() => {
						this.$message({
							type: 'success',
							message: '删除成功!'
						});
						this.programmeList()
					})
				}).catch(() => {

				});
			},
			//搜索点击
			keywordclick() {
				if (this.input3) {
					this.datalisttype = 1
					this.pagenum = 1
					this.keywordsearch()
				} else {
					this.inputclear()
					// this.$message({
					// 	type: 'warning',
					// 	message: '请输入关键字!'
					// });
				}
			},
			//检索
			async keywordsearch() {
				this.tableData = []
				this.tableData = 0
				await this.$post('programme/getProgrammeListByName', {
					name: this.input3,
					pagenum: this.pagenum,
					pagesize: 20,
					userId: this.userId
				}).then((res) => {
					this.tableData = res.list
					this.totalnumber = res.total
				})

				this.$set(this.tableData)
			},
			//新建方案
			onSubmit(datas) {
				if (this.dialogindex == 0) {
					this.$refs['ruleForm'].validate((valid) => {
						if (valid) {
							this.ruleForm.userId = this.userId
							this.$post('programme/createProgramme', this.ruleForm).then((res) => {
								this.$message({
									message: this.ruleForm.id ? '修改成功' : '新建成功',
									type: 'success'
								});
								this.editshow = false
								this.programmeList()
							})
						} else {
							this.$message({
								message: '请完善表单信息',
								type: 'warning'
							});
						}
					});
				}
			},
			//打开新增或者修改的弹出框
			seeprogramme(datas) {
				if (datas) {
					this.ruleForm.poName = datas.poName
					this.ruleForm.poContext = datas.poContext
					this.ruleForm.id = datas.id
				}
				this.editshow = true
				this.dialogindex = 0
			},
			//获取方案列表
			async programmeList() {
				this.tableData = []
				this.totalnumber = 0
				await this.$post('programme/getProgrammeList', {
					pagenum: this.pagenum,
					pagesize: 20,
					userId: this.userId,
					roleId: this.roleId
				}).then((res) => {
					this.tableData = res.list
					this.totalnumber = res.total
				})
				this.$set(this.tableData)
			}
		}
	}
</script>

<style lang="less" scoped="scoped">
	@import "../../static/css/base.less";
	.adminprice{
		display: block;
		font-size: 16px;
		color: #999;
		margin-bottom: 60px;
	}
	.box {
		width: 10%;
	}

	.addforms {
		height: 100%;
	}

	.historybutton {
		text-align: left;
		margin: 0 0 60px 0;

		>:first-child {
			// margin-right: 440px;
		}

		.ioasd {
			float: right;
		}
	}

	.historydata {
		height: 70vh;
		overflow-y: auto;

		>* {
			display: inline-block;
			vertical-align: top;
			height: 100%;
			overflow-y: auto;
		}

		.historydatabody {
			margin-left: 5%;
			width: 85%;

			.version,
			.times {
				font-size: 16px;
				color: #666;
				margin-bottom: 15px;
			}

			.version {}

			.times {
				>span {
					display: inline-block;
					margin: 0 0 0 20%;
					width: 20%;
				}
			}
		}
	}

	.search {
		width: 50%;
		margin: 20px 0;
	}

	.buttonsteam {
		text-align: center;

		>* {
			margin-bottom: 10px;
		}
		>:nth-child(4n){
			margin-left: 0;
		}
	}

	.menuManage .el-select-dropdown {
		width: 52%;
	}


	.menuManage {
		.el-scrollbar {
			ul {
				overflow: hidden;

				li {
					float: left;

					i {
						font-size: 20px;
					}
				}
			}

			ul.el-select-dropdown__list {
				overflow: hidden;

			}
		}

		.tips {
			font-size: 14px;
			color: red;
			margin: 10px;

			span {
				color: red;
				display: inline-block;
				margin: 0 6px;
			}
		}

		.menuBox {
			margin-top: 40px;

			.menuList {
				margin-top: 40px;

				i {
					cursor: pointer;
				}
			}
		}

		.addMenu {
			width: 60%;
			margin-left: 20%;
			margin-top: 100px;
			background: #ffffff;

			h3 {
				border-bottom: solid 1px #cccccc;
				padding: 15px;
				font-size: 18px;
				font-weight: bold;
			}

			.addList {
				padding: 15px;

				.btn {
					text-align: center;
					margin-top: 20px;
				}

				.menuIcon {
					.el-input {
						width: 200px;
					}
				}
			}
		}

		.layer {
			background: rgba(3, 3, 3, 0.8);
			height: calc(~"100vh");
			width: calc(~"100vw");

			position: fixed;
			z-index: 10;
			top: 0;
			left: 0;

			i {
				position: absolute;
				top: 5px;
				right: 30px;
				z-index: 20;
				font-size: 30px;
				color: #ffffff;
			}
		}
	}

	.pagination {
		text-align: center;
		margin: 40px 0;
	}
</style>
<style>
	.search .el-input-group__prepend {
		/* width: 80px; */
	}

	.menuList .aleft {
		margin-left: 20px;
		cursor: pointer;
	}

	.menuList .aleft2 {
		margin-left: 15px;
		cursor: pointer;
	}
</style>
